import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Context } from '../../context.js'

import s from './index.module.css'

import Social from '../social'

import Menu from '../../icons/menu-button.svg'

const HeaderCmp = (props) => {
  return (
    <header className={s.cmp} style={props.style}>
      <div>
        <h1>
          <Link to='/'>TANZ</Link>
        </h1>
        <div>
          <h2>Beauty & Aesthetics</h2>
        </div>
      </div>
      <div className={s.main}>
        <button onClick={() => props.toggleNav()} className={s.menuBtn}>
          <img alt='Menu' src={Menu} />
        </button>
        <div className={s.menu} style={{
          left: props.nav ? 0 : '-100vh'
        }}>
          <nav className={s.nav} role='navigation'>
            <a href='/treatments/'>Book Now</a>
            <a href='/portfolio/'>Portfolio</a>
            <NavLink to='/find-us/'>Find Us</NavLink>
            <NavLink to='/contact-us/'>Contact Us</NavLink>
          </nav>
          <div className={s.contacts}>
            <a href='tel:+442084648389'>0208 464 8389</a>
            <Social cls={s.social} />
          </div>
        </div>
        <div className={s.mainMenu} style={{
          left: props.nav ? 0 : '-100vh'
        }}>
          <div className={s.contacts}>
            <a href='tel:+442084648389'>0208 464 8389</a>
            <Social cls={s.social} />
          </div>
          <nav className={s.nav} role='navigation'>
            <a href='/treatments/'>Book Now</a>
            <a href='/portfolio/'>Portfolio</a>
            <NavLink to='/find-us/'>Find Us</NavLink>
            <NavLink to='/contact-us/'>Contact Us</NavLink>
          </nav>
        </div>
      </div>
    </header>
  )
}

const Header = props => (
  <Context.Consumer>
    {consumer => <HeaderCmp {...props} {...consumer} />}
  </Context.Consumer>
)

export default Header

import React, {Fragment} from 'react'

import Header from '../../components/header'
import Footer from '../../components/footer'

import s from './index.module.css'

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <main className={s.cmp}>
        {children}
      </main>
      <Footer />
      <div className={s.off}>
        <nav>
          <a href='https://empirebeautyschool.co.uk/' title='5* Aesthetics & Beauty Training
'>Empire Beauty School provides elite aesthetics and beauty training to individuals and businesses.
With decades of experience, an advanced curriculum and a industry leading alumni support network training with us is the investment worth making!</a>
        </nav>
      </div>
    </Fragment>
  )
}

export default Layout

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

export const Context = React.createContext()

const KEY = 't.choices'
const DISCOUNT_KEY = 't.discount'

class ProviderCmp extends Component {
  desktopMediaMatch = matchMedia('(min-width: 1024px)')
  tabletMediaMatch = matchMedia('(min-width: 768px)')

  constructor (props) {
    super(props)
    const data = sessionStorage.getItem(KEY)

    const state = {
      desktop: this.desktopMediaMatch.matches,
      tablet: this.tabletMediaMatch.matches,
      formSuccess: false,
      validBooking: false,
      nav: false,
      selectedItems: []
    }

    this.state = {}

    this.state = state

    if (data) this.state = Object.assign({}, state, { selectedItems: JSON.parse(data) })

    this.bindAnalytics(props)
    this.bindMediaQueries()
  }

  bindAnalytics (props) {
    props.history.listen((e) => {
      document.body.classList.remove('locked')
      setTimeout(() => { window.scrollTo(0, 0) }, 500)
      this.setState({ nav: false })
      window.gtag && window.gtag('config', 'UA-126290103-1', { 'page_path': e.pathname })
    })
  }

  bindMediaQueries () {
    this.desktopMediaMatch.addListener((e) => {
      this.setState({
        desktop: e.matches
      })
    })

    this.tabletMediaMatch.addListener((e) => {
      this.setState({
        tablet: e.matches
      })
    })
  }

  sendContact = async (e) => {
    e.preventDefault()
    this.setState({
      formSuccess: false,
      formError: false
    })

    const data = new FormData(e.target)
    const jsonData = {}
    for (const e of data.entries()) jsonData[e[0]] = e[1]

    if (jsonData['_gotcha'].length) {
      return false
    }

    const res = await fetch(process.env.REACT_APP_CONTACT_ENDPOINT, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      referrer: 'no-referrer',
      body: JSON.stringify(jsonData)
    })

    if (res && res.status === 200) {
      window.fbq && window.fbq('track', 'Lead')
      this.setState({ formSuccess: true })
    } else {
      this.setState({ formError: true })
    }
  }

  toggleNav = () => {
    if (this.state.nav) {
      document.body.classList.remove('locked')
    } else {
      document.body.classList.add('locked')
    }
    this.setState({ nav: !this.state.nav })
  }

  addItem = (item) => {
    const {selectedItems} = this.state
    const added = Array.from(selectedItems)
    added.push(item)
    window.gtag && window.gtag('event', 'add_to_cart', {
      "content_type": "product",
      "items": [{ name: item.title, ...item }]
    })
    this.setState({
      selectedItems: added
    }, () => this.updateStore())
  }

  removeItem = (title) => {
    const {selectedItems} = this.state
    const removed = Array.from(selectedItems)
    const index = removed.findIndex(i => i.title === title)
    const item = { ...removed[index] }
    removed.splice(index, 1)
    window.gtag && window.gtag('event', 'remove_from_cart', {
      "content_type": "product",
      "items": [{ name: item.title, ...item }]
    })
    this.setState({
      selectedItems: removed
    }, () => this.updateStore())
  }

  updateStore = () => {
    sessionStorage.setItem(KEY, JSON.stringify(this.state.selectedItems))
  }

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          contact: this.sendContact,
          addItem: this.addItem,
          removeItem: this.removeItem,
          toggleNav: this.toggleNav
        }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }
}

const Provider = withRouter(ProviderCmp)

export {
  Provider as default,
  Provider
}

import React from 'react'

const Address = () => (
  <address className="vcard">
    <div className="adr">
      <div className="street-address">37A London Road</div>
      <div className="locality">Bromley</div> 
      <div className="postal-code">BR1 1DG</div>
      <div className="country-name">United Kingdom</div>
    </div>
    <div className="tel">Tel: +442084648389</div>
    <div className="email">Email: <span className="email">hello@tanzbromley.com</span></div>
  </address>
)

export default Address

import React, {Component, Fragment} from 'react'
import { Context } from '../../context.js'

import s from './index.module.css'
import OpeningTimes from '../../components/opening-times'
import Address from '../../components/address'
import Reviews from '../../components/reviews'
import Links from '../../components/links'
import Description from '../../components/descriptions'

const nailServices = {
  section: 'Nails',
  introduction: null,
  items: [
    {
      id: 'express-manicure',
      title: 'Express Manicure',
      description: 'file . paint',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 12
        },
        {
          type: 'Gel',
          price: 20
        }
      ],
      duration: '20 minutes',
      durationInMinutes: 20
    },
    {
      id: 'signature-manicure',
      title: 'Signature Manicure',
      description: 'file . shape . cuticle push back . moisture . paint',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 20
        },
        {
          type: 'Gel',
          price: 25
        }
      ],
      duration: '30 minutes',
      durationInMinutes: 30
    },
    {
      id: 'deluxe-manicure',
      title: 'Deluxe Manicure',
      description: 'file . shape . cuticle care . scrub. mask . hot mitts . mini massage . paint . oil',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 25
        },
        {
          type: 'Gel',
          price: 35
        }
      ],
      duration: '45 minutes',
      durationInMinutes: 45
    },
    {
      id: 'express-pedicure',
      title: 'Express Pedicure',
      description: 'file . paint',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 15
        },
        {
          type: 'Gel',
          price: 25
        }
      ],
      duration: '20 minutes',
      durationInMinutes: 20
    },
    {
      id: 'signature-pedicure',
      title: 'Signature Pedicure',
      description: 'file . shape . cuticle push back . moisture.  paint',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 20
        },
        {
          type: 'Gel',
          price: 30
        }
      ],
      duration: '30 minutes',
      durationInMinutes: 30
    },
    {
      id: 'deluxe-pedicure',
      title: 'Deluxe Pedicure',
      description: 'Foot bath of petals . shape . cuticle care . callus treatment . exfoliating scrub . hot stone massage',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 30
        },
        {
          type: 'Gel',
          price: 40
        }
      ],
      duration: '45 minutes',
      durationInMinutes: 45
    },
    {
      id: 'express-manicure-pedicure',
      title: 'Express Manicure & Pedicure',
      description: 'file . paint',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 22
        },
        {
          type: 'Gel',
          price: 40
        }
      ],
      duration: '40 minutes',
      durationInMinutes: 40
    },
    {
      id: 'signature-manicure-pedicure',
      title: 'Signature Manicure & Pedicure',
      description: 'file . shape . cuticle push back . moisture . paint ',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 35
        },
        {
          type: 'Gel',
          price: 55
        }
      ],
      duration: '1 hour',
      durationInMins: 60
    },
    {
      id: 'deluxe-manicure-pedicure',
      title: 'Deluxe Manicure & Pedicure',
      description: 'foot bath of petals . shape . cuticle care . callus treatment . exfoliating scrub . Hot Stone massage . paint . oil',
      image: null,
      prices: [
        {
          'type': 'Polish',
          price: 50
        },
        {
          type: 'Gel',
          price: 70
        }
      ],
      duration: '1 hour and 30 minutes',
      durationInMins: 90
    },
    {
      id: 'deluxe-manicure-pedicure',
      title: 'Gel Removal',
      description: 'gel removal . clean . moisturise',
      image: null,
      price: 5,
      duration: '15 minutes',
      durationInMins: 15
    }
  ]
}

const nailExtensionServices = {
  section: 'Hard Gel Nail Extensions',
  introduction: null,
  items: [
    {
      id: 'full-set-gel-extensions',
      title: 'Full Set Of Extensions',
      description: null,
      image: null,
      price: 35,
      duration: '1 hour and 20 minutes',
      durationInMins: 80
    },
    {
      id: 'natural-nails-gel-extensions',
      title: 'Natural Nails',
      description: null,
      image: null,
      price: 30,
      duration: '1 hour',
      durationInMins: 60
    },
    {
      id: 'infill-extensions',
      title: 'Infill',
      description: null,
      image: null,
      price: 25,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'single-nail-replacement',
      title: 'Single Nail Replacement',
      description: null,
      image: null,
      price: 5,
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'nail-removal',
      title: 'Removal',
      description: null,
      image: null,
      price: 10,
      duration: '15 minutes',
      durationInMins: 15
    },
  ]
}

const nailSNSExtensionServices = {
  section: 'SNS Nail Extensions',
  introduction: null,
  items: [
    {
      id: 'sns-full-set-extensions',
      title: 'SNS Full Set Extensions',
      description: null,
      image: null,
      price: 35,
      duration: '1 hour and 20 minutes',
      durationInMins: 80
    },
    {
      id: 'sns-natural-nails-gel-extensions',
      title: 'SNS Natural Nails',
      description: null,
      image: null,
      price: 20,
      duration: '1 hour',
      durationInMins: 60
    },
    {
      id: 'infil-extensions',
      title: 'SNS Infill',
      description: null,
      image: null,
      price: 25,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'sns-nail-removal',
      title: 'SNS Removal',
      description: null,
      image: null,
      price: 10,
      duration: '15 minutes',
      durationInMins: 15
    },
  ]
}

const eyelashExtensionsServices = {
  section: 'Eyelash Extensions',
  introduction: null,
  items: [
    {
      id: 'cluster-lashes',
      title: 'Cluster Lashes',
      description: 'Beautiful cluster lashes that last up to a week',
      image: null,
      price: 20,
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'twod-cluster-lashes',
      title: 'Classic 2D Individual Lashes',
      description: 'A subtle, natural classic lash look',
      image: null,
      price: 50,
      duration: '1 hour and 45 minutes',
      durationInMins: 105
    },
    {
      id: 'twod-cluster-lashes-infills',
      title: 'Classic 2D Infills',
      description: 'Keep your lashes fresh with our infills (within 3 weeks)',
      image: null,
      price: 30,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'threed-cluster-lashes',
      title: 'Glam 3D Individual Lashes',
      description: 'An everyday glamorous lash look',
      image: null,
      price: 60,
      duration: '1 hour and 45 minutes',
      durationInMins: 105
    },
    {
      id: 'threed-cluster-lashes-infills',
      title: 'Glam 3D Infills',
      description: 'Keep your lashes fresh with our infills (within 3 weeks)',
      image: null,
      price: 30,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'russian-individual-lashes',
      title: 'Russian 4D Individual Lashes',
      description: 'An everyday glamorous lash look',
      image: null,
      price: 70,
      duration: '1 hour and 45 minutes',
      durationInMins: 105
    },
    {
      id: 'russian-individual-lashes-infills',
      title: 'Russian 4D Individual Lashes Infills',
      description: 'Keep your lashes fresh with our infills (within 3 weeks)',
      image: null,
      price: 30,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'russian-individual-lashes-infills',
      title: 'Eyelash Removal',
      description: 'Includes gold collagen eye mask',
      image: null,
      price: 10,
      duration: '20 minutes',
      durationInMins: 20
    },
  ]
}

const lashAndBrowServices = {
  section: 'Lash & Brow',
  introduction: null,
  items: [
    {
      id: 'eyelash-tint',
      title: 'Eyelash Tint',
      description: 'Includes gold collagen eye mask',
      image: null,
      price: 12,
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'lash-lift-tint',
      title: 'Lash Lift & Tint',
      description: 'Includes gold collagen eye mask',
      image: null,
      price: 45,
      duration: '55 minutes',
      durationInMins: 55
    },
    {
      id: 'eyebrow-tint',
      title: 'Eyebrow Tint',
      description: 'Includes gold collagen eye mask',
      image: null,
      price: 10,
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'eyebrow-threading',
      title: 'Eyebrow Threading',
      description: 'Includes gold collagen eye mask',
      image: null,
      price: 10,
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'eyebrow-waxing',
      title: 'Eyebrow Waxing',
      description: 'Includes temple massage',
      image: null,
      price: 8,
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'eyelash-eyebrow-tint-shape',
      title: 'Eyelash, Eyebrow Tint & Shape',
      description: 'Includes a choice of waxing, threading or tweezing',
      image: null,
      price: 25,
      duration: '25 minutes',
      durationInMins: 25
    },
    {
      id: 'eyebrow-tint-shape',
      title: 'Eyebrow Tint & Shape',
      description: 'Includes a choice of waxing, threading or tweezing',
      image: null,
      price: 18,
      duration: '20 minutes',
      durationInMins: 20
    },
    {
      id: 'defined-brows',
      title: 'Defined Brows',
      description: 'Includes waxing, threading, tinting  and optional brow powder to finish',
      image: null,
      price: 25,
      duration: '25 minutes',
      durationInMins: 25
    },
    {
      id: 'lash-lift',
      title: 'Lash Lift',
      description: 'Includes gold collagen face mask',
      image: null,
      price: 40,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'lash-lift-tint',
      title: 'Lash Lift & Tint ',
      description: 'Includes gold collagen face mask',
      image: null,
      price: 45,
      duration: '55 minutes',
      durationInMins: 55
    },
    {
      id: 'lash-brow-tint',
      title: 'Eyelash & Eyebrow Tint',
      description: 'Includes temple massage',
      image: null,
      price: 20,
      duration: '20 minutes',
      durationInMins: 20
    },
    {
      id: 'eyebrow-shape',
      title: 'Eyebrow Shape',
      description: 'Includes tweezing and temple massage',
      image: null,
      price: 8,
      duration: '20 minutes',
      durationInMins: 20
    },
  ]
}

const waxingServices = {
  section: 'Waxing',
  introduction: null,
  items: [
    {
      id: 'half-leg-wax',
      title: 'Half Leg Strip Wax',
      description: null,
      image: null,
      price: 20,
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'full-leg-wax',
      title: 'Full Leg Strip Wax',
      description: null,
      image: null,
      price: 25,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'bikini-line-wax',
      title: 'Bikini Line Wax',
      description: null,
      image: null,
      prices: [
        {
          'type': 'Strip Wax',
          price: 12
        },
        {
          type: 'Hot Wax',
          price: 15
        }
      ],
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'extended-bikini-line-wax',
      title: 'Extended Bikini Line Wax',
      description: null,
      image: null,
      prices: [
        {
          'type': 'Strip Wax',
          price: 15
        },
        {
          type: 'Hot Wax',
          price: 18
        }
      ],
      duration: '20 minutes',
      durationInMins: 20
    },
    {
      id: 'brazilian-wax',
      title: 'Brazilian Wax',
      description: null,
      image: null,
      prices: [
        {
          'type': 'Strip Wax',
          price: 25
        },
        {
          type: 'Hot Wax',
          price: 30
        }
      ],
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'holywood-wax',
      title: 'Holywood Wax',
      description: null,
      image: null,
      prices: [
        {
          'type': 'Strip Wax',
          price: 30
        },
        {
          type: 'Hot Wax',
          price: 35
        }
      ],
      duration: '40 minutes',
      durationInMins: 40
    },
    {
      id: 'underarm-wax',
      title: 'Underarm Wax',
      description: null,
      image: null,
      price: 10,
      duration: '10 minutes',
      durationInMins: 10
    },
    {
      id: 'halfarm-wax',
      title: 'Half Arm Wax',
      description: null,
      image: null,
      price: 15,
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'fullarm-wax',
      title: 'Full Arm Wax',
      description: null,
      image: null,
      price: 20,
      duration: '20 minutes',
      durationInMins: 20
    },
    {
      id: 'chin-wax',
      title: 'Chin Wax',
      description: null,
      image: null,
      price: 5,
      duration: '10 minutes',
      durationInMins: 10
    },
    {
      id: 'lip-wax',
      title: 'Lip Wax',
      description: null,
      image: null,
      price: 5,
      duration: '10 minutes',
      durationInMins: 10
    },
    {
      id: 'buttock-wax',
      title: 'Buttock Wax',
      description: null,
      image: null,
      price: 15,
      duration: '20 minutes',
      durationInMins: 20
    },
    {
      id: 'full-back-wax',
      title: 'Full Back Wax',
      description: null,
      image: null,
      price: 25,
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'upper-back-wax',
      title: 'Upper Back Wax',
      description: null,
      image: null,
      price: 20,
      duration: '20 minutes',
      durationInMins: 20
    },
    {
      id: 'chest-wax',
      title: 'Chest Wax',
      description: null,
      image: null,
      price: 15,
      duration: '20 minutes',
      durationInMins: 20
    },
    {
      id: 'chest-stomach-wax',
      title: 'Chest & Stomach Wax',
      description: null,
      image: null,
      price: 30,
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'extra-wax',
      title: 'Hands/Feet/Ears/Nostrils Wax',
      description: null,
      image: null,
      price: 9,
      duration: '10 minutes',
      durationInMins: 10
    }
  ]
}

const threadingServices = {
  section: 'Threading',
  introduction: null,
  items: [
    {
      id: 'eyebrow-threading',
      title: 'Eyebrow',
      description: null,
      image: null,
      price: 10,
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'forehead-threading',
      title: 'Forehead',
      description: null,
      image: null,
      price: 6,
      duration: '10 minutes',
      durationInMins: 10
    },
    {
      id: 'upperlip-threading',
      title: 'Upper Lip',
      description: null,
      image: null,
      price: 6,
      duration: '10 minutes',
      durationInMins: 10
    },
    {
      id: 'chin-threading',
      title: 'Chin',
      description: null,
      image: null,
      price: 5,
      duration: '10 minutes',
      durationInMins: 10
    },
    {
      id: 'neck-threading',
      title: 'Neck',
      description: null,
      image: null,
      price: 8,
      duration: '10 minutes',
      durationInMins: 10
    },
    {
      id: 'sideface-threading',
      title: 'Sides of Face',
      description: null,
      image: null,
      price: 10,
      duration: '15 minutes',
      durationInMins: 15
    },
    {
      id: 'fullface-threading',
      title: 'Full Face',
      description: null,
      image: null,
      price: 25,
      duration: '20 minutes',
      durationInMins: 20
    }
  ]
}

const facialServices = {
  section: 'Facials',
  introduction: null,
  items: [
    {
      id: 'dermologica-prescriptive-facial',
      title: 'Dermalogica Prescriptive Facial',
      description: null,
      image: null,
      price: 35,
      duration: '1 hour',
      durationInMins: 60
    },
    {
      id: 'dermologica-prescriptive-facial-massage',
      title: 'Dermalogica Prescriptive Facial With Neck and Shoulder Massage',
      description: null,
      image: null,
      price: 45,
      duration: '1 hour 15 minutes',
      durationInMins: 75
    },
    {
      id: 'radiofrequency-facial',
      title: 'Radiofrequency Skin Tightening and Rejuvenation Facial',
      description: null,
      image: null,
      price: 45,
      duration: '40 minutes',
      durationInMins: 40
    },
    {
      id: 'microdermabrasion-facial',
      title: 'Microdermabrasion Facial',
      description: null,
      image: null,
      price: 55,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'microdermabrasion-facial',
      title: 'Microdermabrasion Facial Course of 3',
      description: null,
      image: null,
      price: 145,
      duration: '45 minutes per session',
      durationInMins: 45
    }
  ]
}

const massageServices = {
  section: 'Massage',
  introduction: null,
  items: [
    {
      id: 'swedish-full-massage',
      title: 'Full Body Swedish Massage',
      description: null,
      image: null,
      price: 50,
      duration: '1 hour',
      durationInMins: 60
    },
    {
      id: 'swedish-full-massage',
      title: 'Neck, Back and Shoulder Massage',
      description: null,
      image: null,
      price: 30,
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'swedish-full-massage',
      title: 'Lymphatic Drainage With Ultrasonic Cavitation Massage',
      description: null,
      image: null,
      price: 50,
      duration: '1 hour',
      durationInMins: 60
    },
    {
      id: 'swedish-full-massage',
      title: 'Lipo Massage With Radio Frequency',
      description: null,
      image: null,
      price: 50,
      duration: '40 minutes',
      durationInMins: 40
    }
  ]
}

const tanningServices = {
  section: 'Tanning',
  introduction: null,
  items: [
    {
      id: 'tanning-per-min',
      title: 'Tanning per minute',
      description: null,
      image: null,
      prices: [
        {
          type: '1 minute',
          price: '0.85',
          durationInMins: 1
        },
        {
          type: '3 minutes',
          price: '2.50',
          durationInMins: 3
        },
        {
          type: '6 minutes',
          price: '5',
          durationInMins: 6
        },
        {
          type: '9 minutes',
          price: '7.50',
          durationInMins: 9
        },
        {
          type: '12 minutes',
          price: '10',
          durationInMins: 12
        },
        {
          type: '15 minutes',
          price: '12.50',
          durationInMins: 15
        }
      ]
    },
    {
      id: 'tanning-courses',
      title: 'Tanning Courses',
      description: null,
      image: null,
      prices: [
        {
          type: '30 minutes',
          price: '20',
          durationInMins: 30
        },
        {
          type: '45 minutes',
          price: '25',
          durationInMins: 45
        },
        {
          type: '60 minutes',
          price: '30',
          durationInMins: 60
        },
        {
          type: '90 minutes',
          price: '45',
          durationInMins: 60
        }
      ]
    },
    {
      id: 'full-body-spray-tan',
      title: 'Full Body Sienna Spray Tan',
      description: null,
      image: null,
      price: 20,
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'half-body-spray-tan',
      title: 'Half Body Sienna Spray Tan',
      description: null,
      image: null,
      price: 15,
      duration: '20 minutes',
      durationInMins: 20
    }
  ]
}

const makeupServices = {
  section: 'Make Up',
  introduction: null,
  items: [
    {
      id: 'smoky-eye',
      title: 'Smoky Eye',
      description: 'Includes strip or cluster lashes',
      image: null,
      price: 30,
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'teenage-dream',
      title: 'Teenage Dream Full Face',
      description: null,
      image: null,
      price: 25,
      duration: '30 minutes',
      durationInMins: 30
    },
    {
      id: 'subtle-glam',
      title: 'Subtle Glam Full Face',
      description: 'Includes strip or cluster lashes',
      image: null,
      price: 45,
      duration: '45 minutes',
      durationInMins: 45
    },
    {
      id: 'super-glam',
      title: 'Super Glam Full Face',
      description: 'Includes strip or cluster lashes',
      image: null,
      price: 55,
      duration: '1 hour and 15 minutes',
      durationInMins: 75
    }
  ]
}

const spmuServices = {
  section: 'Semi Permanent Make Up',
  introduction: null,
  items: [
    {
      id: 'threed-hairstrokes-brow',
      title: '3D Hairstroke Brows',
      description: null,
      image: null,
      price: 350,
      duration: '3 hours',
      durationInMins: 180
    },
    {
      id: 'ombre-powder-brows',
      title: 'Ombre Powder Brows',
      description: null,
      image: null,
      price: 325,
      duration: '3 hours',
      durationInMins: 180
    },
    {
      id: 'microblading',
      title: 'Microblading',
      description: null,
      image: null,
      price: 300,
      duration: '2 hours and 30 minutes',
      durationInMins: 150
    },
    {
      id: 'lip-blush',
      title: 'Lip Blush & Contour',
      description: null,
      image: null,
      price: 350,
      duration: '3 hours',
      durationInMins: 180
    },
    {
      id: 'eyelash-enhancement-or',
      title: 'Eyelash Enhancement (Top or bottom)',
      description: null,
      image: null,
      price: 200,
      duration: '2 hours',
      durationInMins: 120
    },
    {
      id: 'eyelash-enhancement-and',
      title: 'Eyelash Enhancement (Top and bottom)',
      description: null,
      image: null,
      price: 300,
      duration: '3 hours',
      durationInMins: 180
    },
    {
      id: 'winged-eyelash-enhancement-or',
      title: 'Winged Eyeliner Enhancement (Top or bottom)',
      description: null,
      image: null,
      price: 250,
      duration: '2 hours',
      durationInMins: 120
    },
    {
      id: 'winged-eyelash-enhancement-and',
      title: 'Winged Eyeliner Enhancement (Top and bottom)',
      description: null,
      image: null,
      price: 350,
      duration: '3 hours',
      durationInMins: 180
    },
  ]
}

export const services = [].concat(
  nailServices,
  nailExtensionServices,
  nailSNSExtensionServices,
  eyelashExtensionsServices,
  lashAndBrowServices,
  waxingServices,
  threadingServices,
  facialServices,
  massageServices,
  tanningServices,
  makeupServices,
  spmuServices
)

export const nails = [].concat(
  nailServices,
  nailExtensionServices,
  nailSNSExtensionServices
)

export const lashes = [].concat(
  eyelashExtensionsServices,
  lashAndBrowServices
)

export const facials = [].concat(
  facialServices
)

export const waxing = [].concat(
  waxingServices
)

export const threading = [].concat(
  threadingServices
)

export const makeup = [].concat(
  makeupServices
)

export const spmu = [].concat(
  spmuServices
)

export const massage = [].concat(
  massageServices
)

export const servicesMap = {
  nails,
  facials,
  makeup,
  threading,
  waxing,
  massage,
  'lashes-and-brows': lashes,
  'lymphatic-drainage-massage': massage,
  'semi-permanent-makeup': spmu
}

const ServiceItem = ({
  title,
  price,
  inc,
  description,
  duration,
  prices,
  selectedItems,
  addItem,
  removeItem
}) => {

  const hasBeenAdded = selectedItems.length > 0 && selectedItems.findIndex(i => i.title === title) > -1

  const innerAction = (e) => {
    e.preventDefault()
    if (hasBeenAdded) return removeItem(title)
    return addItem({ title, price })
  }

  return (
    <div className={s.service} itemScope itemType='http://schema.org/Product'>
      <div className={s.title}>
        <h3 itemProp='name'>{title}</h3>
      </div>
      <div className={s.desc}>
        {description && (<p itemProp="description">{description}</p>)}
        {duration && (<p>{duration}</p>)}
      </div>
      {!prices && (
        <div className={s.price}>
          <span itemProp='offers' itemScope itemType='http://schema.org/Offer'>
            <meta itemProp='priceCurrency' content='GBP' />
            <link itemProp='availability' href='http://schema.org/InStock' />
            <p itemProp='price'>£{price}</p>
            <span className={s.prop} itemProp='seller' itemScope itemType='http://schema.org/Organization'>
              <span itemProp='name'>'Tanz Beauty & Aesthetics Bromley'</span>
            </span>
          </span>
        </div>
      )}
      {prices && (
        <div className={s.price}>
          {prices.map((i, k) => (
            <span key={k} itemProp='offers' itemScope itemType='http://schema.org/Offer'>
              <p key={k}>{i.type} <span itemProp='price'>£{i.price}</span></p>
              <span className={s.prop} itemProp='seller' itemScope itemType='http://schema.org/Organization'>
                <span itemProp='name'>'Tanz Beauty & Aesthetics Bromley'</span>
              </span>
            </span>
          ))}
        </div>
      )}
      {inc && (<p className={s.note}>{inc}</p>)}
    </div>
  )
}

const ServiceBlock = ({ section, introduction, items, ...props }) => {
  return (
    <div className={s['service-block']}>
      <h2>{section}</h2>
      {introduction && introduction.length && (<p>{introduction}</p>)}
      <div>
        {items.map((i, k) => (
          <ServiceItem
            selectedItems={props.selectedItems}
            addItem={props.addItem}
            removeItem={props.removeItem}
            {...i}
            key={`service-${section}-${k}`}
          />
        ))}
      </div>
    </div>
  )
}

const ServicesCmp = (props) => {
  let target = services
  const { match: { params } } = props
  if (params.p) target = servicesMap[params.p]
  return (
    <Fragment>
      <section className={s.cmp}>
        <div>
          {target.map((i, k) => (
            <ServiceBlock {...props} {...i} key={`block-${k}`} />
          ))}
        </div>
      </section>
    </Fragment>
  )
}

class Services extends Component {
  render () {
    return (
      <div className={s.cmp}>
        <div
          id='wahanda-online-booking-widget-iframe'
          data-widget-url='https://widget.treatwell.co.uk/place/383151/menu/'
        />
        <div className={s.off}>
          <Description />
          <Context.Consumer>
            {consumer => <ServicesCmp {...this.props} {...consumer} />}
          </Context.Consumer>
          <Address />
          <OpeningTimes />
          <Reviews />
          <Links />
        </div>
      </div>
    )
  }
}

export default Services
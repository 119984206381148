import React from 'react'
import s from './index.module.css'

import OpeningTimes from '../../components/opening-times'
import Address from '../../components/address'
import Reviews from '../../components/reviews'
import Links from '../../components/links'

const Team = () => (
  <section className={s.cmp}>
    

    <div className={s.off}>
      <Address />
      <OpeningTimes />
      <Reviews />
      <Links />
    </div>
  </section>
)

export default Team

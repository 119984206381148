import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import s from './index.module.css'

import OpeningTimes from '../../components/opening-times'
import Address from '../../components/address'
import Reviews from '../../components/reviews'
import Links from '../../components/links'

import {ReactComponent as Logo} from '../../images/cl-logo-2020.svg'
import {ReactComponent as Plus} from '../../icons/plus.svg'

const Home = () => (
  <Fragment>
    <section className={s.cmp}>

      <div className={s.flex}>

        <div className={s.logos}>
          <div className={s.t}>
            <h1>
              <Link to='/'>TANZ</Link>
            </h1>
            <div>
              <h2>Beauty & Aesthetics</h2>
            </div>
          </div>

          <Plus className={s.plus} width={24} fill='#c3a46e' stroke='#c3a46e' />

          <a href='https://charmelle.london/?source=sister' title='View our exciting services at Charmelle London'>
            <Logo width={240} />
          </a>
        </div>

        <div className={s.details}>
          <p>Tanz Beauty & Aesthetics has now combined with <a href='https://charmelle.london/?source=sister' title='View our exciting services at Charmelle London'>Charmelle London</a> to deliver a wider variety of services and products for all of our wonderful clients!</p>
          <p>We are still the same team you trust, continuing with our existing services and client loyalty programs just as part of a larger business moving forwards.</p>
          <p>We are excited to welcome you back to our renovated clinic from the <strong>4th July 2020</strong> and are inviting all to book in for their treatments with a special treat when you attend!</p>

          <a className={s.button} href='/treatments/'>Book Now</a>
        </div>
      </div>

    </section>
    <div className={s.off}>
      <h1>Unparalleled service at your local salon</h1>
      <a href='/treatments/'>Book Now</a>
      <Links />

      <h2>
        <a href="https://charmelle.london/laser-hair-removal/">Laser Hair Removal in Bromley by the talented team at Charmelle London</a>
      </h2>
      <div>
        <h2>
          Tanz Beauty & Aesthetics provides premier red carpet level beauty
          treatments in Bromley
        </h2>
        <p>Bespoke ultimate facials</p>
        <p>
          The signature bespoke treatment. Combining medical level machines for
          the A-LIST SECRET LOOK! This treatment is completly tailored to each
          clients skin and areas of concerns, meaning no two treatments are the
          same. Achieving for you the best ultimate results without going under
          the knife. Suitible for all skin types.
        </p>
      </div>

      <div>
        <h3>Bromleys best manicures and pedicures</h3>
        <p>
          Our clients love us and we love them too! All of our manicure and
          pedicure treatments focus on the essential needs of our clients. Using
          CND Shellac, Gelux, Gelish we know the best products to give our
          clients the best manicure and pedicure looks.
        </p>
        <p>
          Our team at Tanz Beauty & Aesthetics are experienced and friendly. The
          atmosphere at our beauty salon a short walk from Bromley North and
          Bromley town centre is nothing less than a five star experience
        </p>
      </div>

      <h3>Bromleys best massages and facials</h3>
      <h3>Amazing quality waxing for women</h3>
      <h3>Industry leading non-surgical beauty treatments including butt lifts, microdermabrasion facials and lipo suction massages.</h3>

      <h5>With two ultra high quality beauty rooms, two manicure stations and a landmark pedicure station come and experience the best in Bromley.</h5>
      <h4>
        <a href="https://charmelle.london/laser-hair-removal-for-men/">Laser Hair Removal for Men in Bromley by the expert team at Charmelle London</a>
      </h4>
      <p>We love your brows as much as you do! Thats why we have a dedictaed lash and brows station dedicated to fulfil your needs. From eyebrow threading, to russian 4d lashes and beyond we have you covered at our luxury salon.</p>

      <h4>
        Tanz Beauty & Aesthetics in Bromley caters for all kinds of beauty.
      </h4>
      <p>
        Feel like royalty from the moment you enter in this chic and elegantly
        decorated salon laced with gold throughout and located just an 8-minute
        walk from Bromley North Station.
      </p>
      <p>
        With the aim to make you beautiful from head to toe they offer an
        extensive menu with all the essentials from Shellac nails, waxing and
        lashes to massages, facials and makeup.
      </p>
      <p>
        The experienced team use renowned brands from Dermalogica, Lycon and OPI
        to ensure you receive the best treatment possible.
      </p>
      <p>
        Make your next appointment at Tanz Beauty & Aesthetics and feel your
        most beautiful self today.
      </p>

      <h3>Lymphatic Drainage Massage</h3>
      <p>Recovering from liposuction surgery and need a high quality source of lymphatic drainage massage - Tanz Beauty & Aesthetics should be your go to source.</p>
      <p>With over ten years of experience in massage and holisitic treatments we can provide deep tissue massage and swedish massage to get you feeling renewed and refreshed.</p>
      <h2>
        <a href="https://charmelle.london/hifu/">HiFu Face Lift and Skin Tightening in Bromley by the expert team at Charmelle London</a>
      </h2>
      <p>Whatever your requirements, Tanz Beauty & Aesthetics can find the procedure to keep you at the top of your game, whether it be relaxing massage including Swedish massage, sports massage and deep tissue massage – skin care, manicures, pedicures, pamper parties, dermal fillers, inch loss treatments, cavitation, radio frequency, laser lipo, cryopolises, waxing or body wraps. We pride ourselves on delivering the best service to our clients and at fantastic price delivering real value.</p>

      <h2>Microblading in Bromley, Kent, Beckenham, Hayes, West Wickham, Locksbottom</h2>

      <h3>Semi permanent makeup in Bromley, Kent, Beckenham, Hayes, West Wickham, Locksbottom</h3>

      <p>We specialise in all semi-permanent make up techniques: microblading, shading, ombre brows, semi permanent eyeliners and semi permanent lip make up, ranging from full lipstick to aquarelle and ombre lips.</p>

      <ul>
        <li>Semi permanent makeup services:</li>
        <li>Semi permanent eyebrows</li>
        <li>Semi permanent eyeliner</li>
        <li>Semi permanent lipblush</li>
        <li>Semi permanent hairstrokes</li>
        <li>Semi permanent ombré powder brows</li>
        <li>Signature ombré brows</li>
        <li>Dermal fillers</li>
        <li>Lip fillers</li>
        <li>The best ombré brows in Bromley, Kent and London</li>
      </ul>

      <p>The Ombré Powder Brow gives clients a very soft diluted colour starting at around 20% gradient at the front of the brows going right through to 80% into the arch and tails. With a lighter front to the brow and a gradual darker depth through to the tail of the brow, this provides the perfect Ombre Brow which results in a soft misty, fluffy finish behind the natural brows. This technique heals to reveal very natural results and lasts for up to three years providing the aftercare is carried out correctly. Please note this is a Cosmetic Tattoo.</p>

      <h4>AESTHETIC AND BEAUTY TREATMENTS IN BROMLEY, LONDON</h4>

      <h4>DERMAL FILLERS</h4>
      <p>Lip and cheek volumising and treating mild to moderate lines</p>
      <p>Dermal fillers are used to contour and create volume as well as to smooth away lines and wrinkles, revitalising the skin and its appearance. Dermal fillers are temporary and gradually dissolve over time</p>
      <p>They are made up of Hyaluronic acid, which already naturally exists within the body. As we age our body’s production of hyaluronic acid gradually declines, resulting in visible signs of ageing. Natural lines and wrinkles deepen, lips lose their fullness, lines appearing around the mouth and nose area, and cheeks lose volume giving them a sunken appearance.</p>

      <h4>SKIN PEELS</h4>
      <p>Treatment of wrinkles, uneven skin texture, acne and pigmentation</p>

      <h4>LVL ENHANCE LASHES</h4>
      <p>LVL Lash Lift gives you the appearance of longer, thicker lashes</p>
      <p>Do you love the volume and glamour of lash extensions, but hate the regular infills and damage it causes to your natural eyelashes? Then LVL enhance lashes are the one for you</p>

      <p>All our treatments work in enhancing the beauty you already possess, because after all happiness and confidence are the prettiest things you can wear.</p>
      <p>Our trained specialists offers full lip treatment, marionette lines, peri-oral lines, corners of mouth, nasal-labial lines, vermillion border of lips and glabellar. Dermal filler is injected at precise locations into your skin. Your nurse will massage the area to ensure a smooth application. The treatment is non-invasive with virtually no recovery time necessary, you can resume your normal activities immediately after treatment. </p>

      <p>Dermal fillers might be for you if:
      You are experiencing lines, wrinkles or sunken cheeks that are making your face look tired
      Your lines and wrinkles are deep in appearance, meaning Botox isn’t right for you
      You feel like having smoother, younger looking skin would make you more confident with your appearance, but you won’t want to go as far as a facelift</p>
      <p>Dermal fillers can be applied on a variety of areas including cheek hollows, nose to mouth (nasolabial folds), around the lips and mouth (periloral lines), lips and mouth to chin lines.</p>

      <p>Juvederm, Revolax</p>

      <p>Lip Augmentation is a cosmetic procedure that can give you fuller, plumper lips with definition and shape if needed. These days, an injectable dermal filler is the most commonly used method of lip augmentation. There are many types of dermal fillers that can be injected into your lips and around your mouth. But the most common fillers today contain substances similar to hyaluronic acid. Hyaluronic acid is a natural substance found in the body. It helps to increase the volume and shape of your lips.Full lips with an accentuated border have often been associated with beauty. The effects typically last around six to twelve months in the lips. After that more injections may be needed to keep the shape and volume of your lips.</p>
      <h4>LIP FILLERS</h4>
      <p>Beautiful lips can enhance your look like no other feature can and our lip filler and contouring treatments can enhance the natural beauty of your lips.</p>
      <p>The lip enhancement treatments that we offer are performed using hyaluronic based products, which aim to restore lip fullness and definition.</p>
      <p>Most patients find that their lips not only appear more attractive, they also feel healthier, softer and more hydrated.</p>

      <OpeningTimes />
      <Address />
      <Reviews />
    </div>
  </Fragment>
)

export default Home

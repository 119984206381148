import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from './context'
import { Helmet } from 'react-helmet'

import Layout from './containers/layout'
import Home from './containers/home'
import Services from './containers/services'
import Contact from './containers/contact'
import FindUs from './containers/find-us'
import Portfolio from './containers/portfolio'
import Team from './containers/team'

const Routes = () => {
  return (
    <Router>
      <Provider>
        <Switch>
          <Route path='/' exact render={(props) => (
            <Layout>
              <Helmet>
                <title>Tanz Beauty & Aesthetics | Beauty Salon | Bromley</title>
                <link rel="canonical" href="https://tanzbromley.com/" />
                <meta name="description" content="Tanz Beauty & Aesthetics Salon offering maincures, pedicures, waxing, tanning, semi permanent make up and aesthetics in Bromley, Kent" />
                <meta name="og:url" content="https://tanzbromley.com/" />
                <meta property="og:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta property="twitter:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta name="twitter:card" content="summary_large_image"></meta>
              </Helmet>
              <Home {...props} />
            </Layout>
          )} />
          <Route path='/find-us/' exact render={(props) => (
            <Layout>
              <Helmet>
                <title>Find Us | Tanz Beauty & Aesthetics</title>
                <link rel="canonical" href="https://tanzbromley.com/find-us/" />
                <meta name="description" content="Find Tanz Beauty & Aesthetics salon in Bromley, Kent. Offering manicures, pedicures, waxing, tanning, semi permanent make up and aesthetics" />
                <meta name="og:url" content="https://tanzbromley.com/find-us/" />
                <meta property="og:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta property="twitter:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta name="twitter:card" content="summary_large_image"></meta>
              </Helmet>
              <FindUs {...props} />
            </Layout>
          )} />
          <Route path='/team/' exact render={(props) => (
            <Layout>
              <Helmet>
                <title>Our Team | Tanz Beauty & Aesthetics</title>
                <link rel="canonical" href="https://tanzbromley.com/team/" />
                <meta name="description" content="The team at Tanz Beauty & Aesthetics salon in Bromley, Kent. Offering manicures, pedicures, waxing, tanning, semi permanent make up and aesthetics" />
                <meta name="og:url" content="https://tanzbromley.com/team/" />
                <meta property="og:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta property="twitter:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta name="twitter:card" content="summary_large_image"></meta>
              </Helmet>
              <Team {...props} />
            </Layout>
          )} />
          <Route path='/contact-us/' exact render={(props) => (
            <Layout>
              <Helmet>
                <title>Contact Us | Tanz Beauty & Aesthetics</title>
                <meta name="description" content="Contact us at Tanz Beauty & Aesthetics salon in Bromley, Kent. Offering manicures, pedicures, waxing, tanning, semi permanent make up and aesthetics" />
                <link rel="canonical" href="https://tanzbromley.com/contact-us/" />
                <meta name="og:url" content="https://tanzbromley.com/contact-us/" />
                <meta property="og:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta property="twitter:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta name="twitter:card" content="summary_large_image"></meta>
              </Helmet>
              <Contact {...props} />
            </Layout>
          )} />
          <Route path='/treatments/' exact render={(props) => (
            <Layout>
              <Helmet>
                <title>Treatments | Tanz Beauty & Aesthetics</title>
                <meta name="description" content="Our fantastic beauty services at Tanz Beauty & Aesthetics salon in Bromley, Kent. Offering manicures, pedicures, waxing, tanning, semi permanent make up and aesthetics" />
                <link rel="canonical" href="https://tanzbromley.com/treatments/" />
                <meta name="og:url" content="https://tanzbromley.com/treatments/" />
                <meta property="og:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta property="twitter:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta name="twitter:card" content="summary_large_image"></meta>
              </Helmet>
              <Services agent={navigator.userAgent} {...props} />
            </Layout>
          )} />
          <Route path='/portfolio/' exact render={(props) => (
            <Layout>
              <Helmet>
                <title>Portfolio | Tanz Beauty & Aesthetics</title>
                <link rel="canonical" href="https://tanzbromley.com/portfolio/" />
                <meta name="description" content="Our portfolio of recent work at Bromleys best manicures, pedicures, waxing, tanning, semi permanent make up and aesthetics plus all your other beauty needs in Bromley, Kent" />
                <meta name="og:url" content="https://tanzbromley.com/" />
                <meta property="og:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta property="twitter:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                <meta name="twitter:card" content="summary_large_image"></meta>
              </Helmet>
              <Portfolio {...props} />
            </Layout>
          )} />
          <Route path='/treatments/:p' exact render={(props) => {
            const { match: { params } } = props
            const name = params.p.charAt(0).toUpperCase() + params.p.slice(1)
            return (
              <Layout>
                <Helmet>
                  <title>{name} | Treatments | Tanz Beauty & Aesthetics</title>
                  <meta name="description" content={`Our fantastic ${name} beauty services at Tanz Beauty & Aesthetics salon in Bromley, Kent. Offering manicures, pedicures, waxing, tanning, semi permanent make up and aesthetics`} />
                  <link rel="canonical" href={`https://tanzbromley.com/treatments/${params.p}/`} />
                  <meta name="og:url" content="https://tanzbromley.com/treatments/" />
                  <meta property="og:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                  <meta property="twitter:image" content="https://tanzbromley.com/logo/tanz-logo%403x.png" />
                  <meta name="twitter:card" content="summary_large_image"></meta>
                </Helmet>
                <Services agent={navigator.userAgent} {...props} />
              </Layout>
            )
          }} />
        </Switch>
      </Provider>
    </Router>
  )
}

export default Routes

import React, { Fragment } from 'react'

function stars (rating) {
  var stars = []
  for (var i = 0; i < 5; i++) {
    if (i < rating) {
      stars += String.fromCharCode(parseInt('2605', 16))
    } else {
      stars += String.fromCharCode(parseInt('2606', 16))
    }
  }
  return stars
}

const Reviews = () => (
  <Fragment>
    <div className="hreview" id="hreview-Excellent-service-from-a-true-professional">
      <h2 className="summary">
        Love my nails and the pedicure with hot stones is a really nice touch.
        Highly recommended. Thanks girls
      </h2>
      <abbr className="dtreviewed" title="2019-01-18T22:41Z">
        Mar 03, 2019
      </abbr>{' '}
      by{' '}
      <span className="reviewer vcard">
        <span className="fn">Paris Stevens</span>
      </span>
      <span className="type">product</span>
      <blockquote className="description">
        <p>
          <abbr title="5" className="rating">
            {stars(5)}
          </abbr>{' '}
          The look and feel of my nails is amazing, the time spent at the
          salon was really good and the staff are really friendly.
        </p>
      </blockquote>
      <span className="version">0.3</span>
    </div>
    <div className="hreview" id="hreview-Brows-make-me-feel-great!">
      <h2 className="summary">
        Lymphatic drainage massage was really enjoyable and has helped a lot
        with my swelling.{' '}
      </h2>
      <abbr className="dtreviewed" title="2019-01-02T12:41Z">
        Jan 02, 2019
      </abbr>{' '}
      by{' '}
      <span className="reviewer vcard">
        <span className="fn">Esther Brooking</span>
      </span>
      <span className="type">product</span>
      <blockquote className="description">
        <p>
          <abbr title="5" className="rating">
            {stars(5)}
          </abbr>{' '}
          The massage left me feeling so relaxed and renewed.
        </p>
      </blockquote>
      <span className="version">0.3</span>
    </div>
    <div className="hreview" id="hreview-Brows-make-me-feel-great!">
      <h2 className="summary">
        All staff are lovely and very attentive. Lovely ambience in salon.
        Defintely recommended
      </h2>
      <abbr className="dtreviewed" title="2018-12-22T08:12Z">
        Dec 22, 2018
      </abbr>{' '}
      by{' '}
      <span className="reviewer vcard">
        <span className="fn">Kara Jamieson</span>
      </span>
      <span className="type">product</span>
      <blockquote className="description">
        <p>
          <abbr title="5" className="rating">
            {stars(5)}
          </abbr>{' '}
          Manicure five stars, pedicure five stars and the non surgical butt
          lift - oh wow!
        </p>
      </blockquote>
      <span className="version">0.3</span>
    </div>
  </Fragment>
)

export default Reviews

import React from 'react'
import s from './index.module.css'

import OpeningTimes from '../../components/opening-times'
import Address from '../../components/address'
import Reviews from '../../components/reviews'
import Links from '../../components/links'

const FindUs = () => (
  <section className={s.cmp}>
    <div className={s.mapouter}>
      <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2488.605010490917!2d0.007694616099029459!3d51.41031197961928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8aa0d36ac03d3%3A0xefd49f9131cbe41e!2sTanz+beauty+and+tanning!5e0!3m2!1sen!2suk!4v1551652120815' width='600' height='450' frameBorder='0' allowFullScreen></iframe>
    </div>

    <div className={s.off}>
      <Address />
      <OpeningTimes />
      <Reviews />
      <Links />
    </div>
  </section>
)

export default FindUs

import React, {Fragment} from 'react'

const Links = () => (
  <Fragment>
    <a href="https://charmelle.london">
      Semi permanent makeup in Bromley by market leading specialist Charmelle
      London
    </a>
    <a href="/treatments/nails/">
      Manicure and pedicure treatments at Tanz Beauty & Aesthetics Bromley
    </a>
    <a href="/treatments/facials/">
      Facial treatments at Tanz Beauty & Aesthetics Bromley
    </a>
    <a href="/treatments/lashes-and-brows/">
      Lashes and brow treatments at Tanz Beauty & Aesthetics Bromley
    </a>
    <a href="/treatments/makeup/">
      Makeup treatments at Tanz Beauty & Aesthetics Bromley
    </a>
    <a href="/treatments/semi-permanent-makeup/">
      Semi permanent makeup treatments at Tanz Beauty & Aesthetics Bromley
    </a>
    <a href="/treatments/threading/">
      Threading treatments at Tanz Beauty & Aesthetics Bromley
    </a>
    <a href="/treatments/waxing/">
      Waxing treatments at Tanz Beauty & Aesthetics Bromley
    </a>
    <a href="/treatments/massage/">
      Massage treatments at Tanz Beauty & Aesthetics Bromley
    </a>
    <a href="/treatments/lymphatic-drainage-massage/">
      Lymphatic Drainage Massage treatments at Tanz Beauty & Aesthetics Bromley
    </a>
  </Fragment>
)

export default Links

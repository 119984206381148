import React from 'react'

const OpeningTimes = () => (
  <table>
    <tbody>
      <tr>
        <td>
          <div />
          <span>
            Mon
          </span>
          <span>
            Monday
          </span>
        </td>
        <td>10:00</td>
        <td>AM</td>
        <td>–</td>
        <td>7:00</td>
        <td>PM</td>
      </tr>
      <tr>
        <td>
          <div />
          <span>
            Tue
          </span>
          <span>
            Tuesday
          </span>
        </td>
        <td>10:00</td>
        <td>AM</td>
        <td>–</td>
        <td>7:00</td>
        <td>PM</td>
      </tr>
      <tr>
        <td>
          <div />
          <span>
            Wed
          </span>
          <span>
            Wednesday
          </span>
        </td>
        <td>10:00</td>
        <td>AM</td>
        <td>–</td>
        <td>7:00</td>
        <td>PM</td>
      </tr>
      <tr>
        <td>
          <div />
          <span>
            Thu
          </span>
          <span>
            Thursday
          </span>
        </td>
        <td>10:00</td>
        <td>AM</td>
        <td>–</td>
        <td>7:00</td>
        <td>PM</td>
      </tr>
      <tr>
        <td>
          <div />
          <span>
            Fri
          </span>
          <span>
            Friday
          </span>
        </td>
        <td>10:00</td>
        <td>AM</td>
        <td>–</td>
        <td>7:00</td>
        <td>PM</td>
      </tr>
      <tr>
        <td>
          <div />
          <span>
            Sat
          </span>
          <span>
            Saturday
          </span>
        </td>
        <td>10:00</td>
        <td>AM</td>
        <td>–</td>
        <td>7:00</td>
        <td>PM</td>
      </tr>
      <tr>
        <td>
          <div />
          <span>
            Sun
          </span>
          <span>
            Sunday
          </span>
        </td>
        <td colSpan="5">Closed</td>
      </tr>
    </tbody>
  </table>
)

export default OpeningTimes

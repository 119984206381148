import React, { useState, useEffect } from 'react'
import instafeed from 'instafeed-lite'

import s from './index.module.css'

import OpeningTimes from '../../components/opening-times'
import Address from '../../components/address'
import Reviews from '../../components/reviews'
import Links from '../../components/links'
import Instagram from './instagram'

export const InstagramOptions = {
  accessToken: '10913103075.1677ed0.7cc05b27a6cf466db80f060cddf0aa68',
  clientId: '275b7c935a0c4a4a9681d6ba4376cfd0',
  get: 'user',
  resolution: 'standard_resolution',
  sortBy: 'most-recent',
  userId: 10913103075,
  limit: 100
}

const Portfolio = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    instafeed(InstagramOptions).then(r => {
      setData(r.data)
      if (window.instgrm) window.instgrm.Embeds.process()
    })
  }, [])

  if (navigator.userAgent === 'ReactSnap') {
    return (
      <div className={s.cmp}>
        <div className={s.off}>
          <Address />
          <OpeningTimes />
          <Reviews />
          <Links />
        </div>
      </div>
    )
  }

  return (
    <section className={s.cmp}>
      <section>
          {data && data.length > 0 && data.map(i => (
            <div key={i.id} className={s.post}>
              <Instagram {...i} />
            </div>
          ))}
        </section>

      <div className={s.off}>
        <Address />
        <OpeningTimes />
        <Reviews />
        <Links />
      </div>
    </section>
  )
}

export default Portfolio

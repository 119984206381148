import React from 'react'
import Fade from 'react-reveal/Fade'
import { Context } from '../../context.js'

import s from './index.module.css'

import OpeningTimes from '../../components/opening-times'
import Address from '../../components/address'
import Reviews from '../../components/reviews'
import Links from '../../components/links'

const ContactCmp = (props) => (
  <Fade right cascade duration={750} delay={250}>
    <section id='contact' className={s.cmp}>
      <div className={s.form}>
        <h2>We're here to help!</h2>
        {!props.formSuccess && (<p>Complete the form below or give us a call on <a href='tel:+442084648389'>0208 464 8389</a></p>)}
        {props.formError && (<p className={s.message}>There was an error submitting your request. Please try again.</p>)}
        {!props.formSuccess && (
          <Fade right cascade duration={750} delay={500}>
            <form onSubmit={props.contact} autoComplete='off'>
              <input type='hidden' name='_subject' value='Tanz Bromley Contact Submission' />
              <input type='text;' name='_gotcha' style={{ 'display': 'none' }} />
              <label>
                <input type='text' name='name' placeholder='Your name:' required />
              </label>
              <label>
                <input type='text' name='number' placeholder='Your contact number:' />
              </label>
              <label>
                <input type='email' name='email' placeholder='Your email address:' required />
              </label>
              <label>
                <textarea placeholder='Your message:' name='message' />
              </label>
              <label className={s.checkbox}>
                <input type='checkbox' name='terms' value='1' required />
                I agree to the terms and conditions and agree my details submitted here will only be used with regards to my enquiry
              </label>
              <button type='submit'>Get in touch</button>
            </form>
          </Fade>
        )}
        {props.formSuccess && (
          <p className={s.message}>Thanks for getting in touch! We will respond as soon as possible.</p>
        )}
      </div>
      <div className={s.off}>
        <a href='https://charmelle.london'>Semi permanent makeup in Bromley by market leading specialist Charmelle London</a>
        <Address />
        <OpeningTimes />
        <Reviews />
        <Links />
      </div>
    </section>
  </Fade>
)

const Contact = props => (
  <Context.Consumer>
    {consumer => <ContactCmp {...props} {...consumer} />}
  </Context.Consumer>
)

export default Contact
import React from 'react'

import s from './index.module.css'

import Facebook from '../../icons/facebook.svg'
import Instagram from '../../icons/instagram.svg'
import Messenger from '../../icons/messenger.svg'

const Social = ({ cls, children }) => (
  <div className={`${s.cmp} ${cls}`}>
    {children}
    <div>
      <a href='https://www.instagram.com/charmellelondonclinic/' target='_blank' rel='noopener noreferrer'>
        <img className={s.logo} src={Instagram} alt='Instagram - TANZ Beauty & Aesthetics Bromley' />
      </a>
      <a href='https://m.me/Tanzbromley' target='_blank' rel='noopener noreferrer'>
        <img className={s.logo} src={Messenger} alt='Messenger - TANZ Beauty & Aesthetics Bromley' />
      </a>
      <a href='https://www.facebook.com/Tanzbromley/' target='_blank' rel='noopener noreferrer'>
        <img className={s.logo} src={Facebook} alt='Facebook - TANZ Beauty & Aesthetics Bromley' />
      </a>
    </div>
  </div>
)

export default Social

import React from 'react'
import { hydrate, render } from "react-dom"
import './index.css'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import config from 'react-reveal/globals'

config({ ssrFadeout: true })

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(<Routes />, rootElement)
} else {
  render(<Routes />, rootElement)
}

if (navigator.userAgent !== 'ReactSnap' && process.env.NODE_ENV === 'production') {
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/rn8g2h3e';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

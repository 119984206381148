import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import s from './index.module.css'

import Social from '../social'

const Footer = () => (
  <Fragment>
    <footer className={s.cmp}>
      <section className={s.row}>
        <div>
          <h1>
            <Link to='/'>TANZ Beauty & Aesthetics Bromley</Link>
          </h1>
        </div>
        <div>
          <Social cls={s.social} />
        </div>
      </section>
    </footer>
    <section className={s.footnote}>
      <p className={s.copy}>&copy;{new Date().getFullYear()} TANZ Beauty & Aesthetics Bromley</p>
      <p className={s.copy}>37a London Road, Bromley, BR1 1DG</p>
    </section>
  </Fragment>
)

export default Footer
